<template id="dashboard">
  <b-container fluid>
    <div v-if="waiting" class="text-center">
      <img :src="loadingImg" style="width: 150px" />
      <h4>Loading...</h4>
    </div>
    <div v-if="!waiting">
      <h5 class="mt-2">
        Matches ({{ total }})
        <b-button
          variant="primary"
          title="Search match"
          class="mr-1"
          @click="openCercaPartita"
          size="sm"
          ><b-icon icon="search"></b-icon
        ></b-button>
        <b-button v-b-toggle.collapse-1 variant="primary" size="sm"
          >Filter evaluated matches</b-button
        >
      </h5>
      <b-collapse id="collapse-1" class="mt-2">
        <b-row class="mb-1">
          <b-col sm="2">
            <b-form-input type="date" v-model="filters.data"></b-form-input>
          </b-col>
          <b-col sm="2">
            <b-input-group>
              <b-form-input
                type="text"
                v-model="filters.squadra"
                placeholder="Team"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" @click="filtraPartite"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col sm="2">
            <b-form-select v-model="filters.osservatore">
              <option value="0">-- Scout --</option>
              <option
                v-for="item in osservatori"
                :value="item.id"
                :key="item.id"
              >
                {{ item.nickname }}
              </option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-collapse>

      <v-infinite-scroll
        :loading="loading"
        @bottom="nextPage"
        :offset="20"
        style="max-height: 80vh; overflow-y: scroll"
        class="row"
      >
        <b-col
          sm="12"
          md="12"
          lg="6"
          v-for="(partita, index) in partite"
          :key="index"
          class="p-0 m-0 mb-2"
        >
          <match-card :match="partita" @openMatch="openMatch" />
        </b-col>
        <div v-if="loading" align="center" style="width: 100%">
          <img :src="loadingImg" style="width: 150px" />
        </div>
      </v-infinite-scroll>
    </div>
    <b-modal
      id="matchModal"
      ref="matchModal"
      title="Search match"
      ok-only
      ok-title="Close"
    >
      <b-form-group id="competizioneGroup" label="Competition">
        <b-form-input
          id="competizione"
          type="text"
          readonly
          v-model="form.competizione"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="partitaGroup" label="Match">
        <b-input-group>
          <b-form-input
            id="partita"
            type="text"
            v-model="form.partita"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="cercaPartita"
              ><b-icon icon="search"></b-icon
            ></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div v-if="show_aree">
        <span>Select an area: </span>
        <b-form-input
          v-model="search_area"
          placeholder="Quick search"
        ></b-form-input>
        <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
          <b-row>
            <b-col
              cols="3"
              v-for="area in filtered_aree"
              style="padding: 0.2rem"
              :key="area.id"
            >
              <b-card
                class="small text-center"
                style="cursor: pointer; height: 100%"
                @click="selezionaArea(area.sigla3)"
              >
                <flag :country="area.sigla" :size="24"></flag><br />
                {{ area.paese }}
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="show_competizioni">
        <div v-if="is_search">
          <h4>Waiting... <img :src="loadingImg" style="width: 150px" /></h4>
        </div>
        <div v-if="!is_search && competizioni.length == 0">
          <h5>No competition found!</h5>
        </div>
        <div v-if="competizioni.length > 0">
          <span>Select a competition: </span>
          <b-form-input
            v-model="search_competizione"
            placeholder="Quick search"
          ></b-form-input>
          <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
            <b-row>
              <b-col
                cols="4"
                v-for="comp in filtered_competizioni"
                style="padding: 0.2rem"
                :key="comp.wyId"
              >
                <b-card
                  class="small text-center"
                  style="cursor: pointer; height: 100%"
                  @click="selezionaCompetizione(comp)"
                >
                  <strong>{{ comp.name }}</strong
                  ><br />
                  <span class="font-size-xs color-rosso">{{ comp.format }}</span
                  ><br />
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div v-if="show_squadre">
        <div v-if="is_search">
          <h4>
            Waiting...
            <img :src="loadingImg" style="width: 150px" />
          </h4>
        </div>
        <div v-if="!is_search && squadre.length == 0">
          <h5>No team found!</h5>
        </div>
        <div v-if="squadre.length > 0">
          <span>Select a team: </span>
          <b-form-input
            v-model="search_squadra"
            placeholder="Quick search"
          ></b-form-input>
          <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
            <b-row>
              <b-col
                cols="4"
                v-for="team in filtered_squadre"
                style="padding: 0.2rem"
                :key="team.id"
              >
                <b-card
                  class="small text-center py-1"
                  style="cursor: pointer; height: 100%"
                  @click="selezionaSquadra(team)"
                >
                  <img :src="team.imageDataURL" width="50" /><br />
                  <strong>{{ team.officialName }}</strong
                  ><br />
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div v-if="show_matches">
        <div v-if="is_search">
          <h4>Waiting... <img :src="loadingImg" style="width: 150px" /></h4>
        </div>
        <div v-if="!is_search && matches.length == 0">
          <h5>No match found!</h5>
        </div>
        <div v-if="matches.length > 0">
          <span>Select a match: </span>
          <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
            <!--b-row
              class="py-1"
              v-for="partita in matches"
              style="border-bottom: 1px solid #e5e5e5; cursor: pointer"
              @click="selezionaPartita(partita)"
              :key="partita.wyId"
            >
              <b-col>
                <h5>{{ partita.nome_partita }}</h5>
              </b-col>
              <b-col>
                {{
                  (partita.data_partita + " " + partita.ora_partita)
                    | formatDateHour
                }}
              </b-col>
            </b-row-->
            <b-row
              class="py-1"
              v-for="partita in matches"
              style="border-bottom: 1px solid #e5e5e5; cursor: pointer"
              @click="selezionaPartita(partita)"
              :key="partita.matchId"
            >
              <b-col>
                <h5>{{ partita.label }}</h5>
              </b-col>
              <b-col>
                {{ partita.dateutc | formatDateHour }}
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-form-group id="dataGroup" label="Date / Hour">
        <b-row>
          <b-col cols="8">
            <b-form-input
              id="data"
              type="date"
              v-model="form.data_partita"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              id="ora"
              type="time"
              v-model="form.ora_partita"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>
      <b-button
        v-if="form.wyscout_id"
        variant="primary"
        class="mt-3"
        @click="openValutaGiocatori"
        >Evaluate players</b-button
      >
    </b-modal>

    <b-modal
      id="playersModal"
      ref="playersModal"
      title="Evaluate players"
      ok-only
      ok-title="Close"
      size="xl"
    >
      <b-row v-if="!is_search">
        <b-col>
          <b-row
            v-for="player in home"
            align-v="center"
            :key="player.wyId"
            class="border-bottom py-2"
          >
            <b-col sm="12">
              <b-form-radio-group
                buttons
                v-model="giocatori[player.wyId].gradimento"
                button-variant="outline-primary"
              >
                <b-form-radio :value="null"
                  ><b-icon icon="blank"></b-icon
                ></b-form-radio>
                <b-form-radio
                  v-for="grad in gradimenti"
                  :value="grad.id"
                  :key="player.wyId + '_' + grad.id"
                >
                  <div v-if="auth.isUserEnable('opta')" :title="grad.nome">
                    <grad1 class="grad" v-if="grad.id == 1" />
                    <grad2 class="grad" v-if="grad.id == 2" />
                    <grad3 class="grad" v-if="grad.id == 3" />
                    <grad4 class="grad" v-if="grad.id == 4" />
                    <grad5 class="grad" v-if="grad.id == 5" />
                  </div>
                  <div v-else style="width: 25px">
                    <div style="font-size: 0.5rem">
                      {{ labelGrad[grad.id - 1] }}
                    </div>
                    <div>{{ siglaGrad[grad.id - 1] }}</div>
                  </div>
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col sm="8">
              <b-form-textarea
                v-model="giocatori[player.wyId].note"
                placeholder="Insert evaluation..."
                rows="3"
                max-rows="3"
                class="mt-2"
              ></b-form-textarea>
            </b-col>
            <b-col sm="4" class="text-center">
              <img :src="player.imageDataURL" height="60" /><br />
              {{ player.shortName }}
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row
            v-for="player in away"
            align-v="center"
            :key="player.wyId"
            class="border-bottom py-2"
          >
            <b-col sm="12" class="text-right">
              <b-form-radio-group
                buttons
                v-model="giocatori[player.wyId].gradimento"
                button-variant="outline-primary"
              >
                <b-form-radio :value="null"
                  ><b-icon icon="blank"></b-icon
                ></b-form-radio>
                <b-form-radio
                  v-for="grad in gradimenti"
                  :value="grad.id"
                  :key="player.wyId + '_' + grad.id"
                >
                  <div v-if="auth.isUserEnable('opta')" :title="grad.nome">
                    <grad1 class="grad" v-if="grad.id == 1" />
                    <grad2 class="grad" v-if="grad.id == 2" />
                    <grad3 class="grad" v-if="grad.id == 3" />
                    <grad4 class="grad" v-if="grad.id == 4" />
                    <grad5 class="grad" v-if="grad.id == 5" />
                  </div>
                  <div v-else style="width: 25px">
                    <div style="font-size: 0.5rem">
                      {{ labelGrad[grad.id - 1] }}
                    </div>
                    <div>{{ siglaGrad[grad.id - 1] }}</div>
                  </div>
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col sm="4" class="text-center">
              <img :src="player.imageDataURL" height="60" /><br />
              {{ player.shortName }}
            </b-col>
            <b-col sm="8">
              <b-form-textarea
                v-model="giocatori[player.wyId].note"
                placeholder="Insert evaluation..."
                rows="3"
                max-rows="3"
                class="mt-2"
              ></b-form-textarea>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="!is_search && typeof formazioni === 'object'">
        <b-row class="teamsSchemes mt-2">
          <b-col class="text-center">
            <span class="scheme">{{ Object.keys(formation_home)[0] }}</span>
          </b-col>
          <b-col class="text-center">
            <span class="scheme">{{ Object.keys(formation_away)[0] }}</span>
          </b-col>
        </b-row>
        <div class="teamSheetPitch">
          <ul
            class="gears-abs gears-fit dotsTeam teamA"
            v-if="Object.keys(formation_home)[0]"
          >
            <li
              v-for="(item, id) in formation_home[
                Object.keys(formation_home)[0]
              ].players"
              :key="id"
              :class="
                'teamA_' +
                item[Object.keys(item)[0]]['position'] +
                '' +
                (getPlayerHome(Object.keys(item)[0]).playerOut > 0
                  ? ' substituted'
                  : '')
              "
            >
              {{ getPlayerHome(Object.keys(item)[0]).shirtNumber }}
              <span class="player-name">{{
                getPlayerHome(Object.keys(item)[0]).shortName
              }}</span>
              <span
                v-if="getPlayerHome(Object.keys(item)[0]).yellowCards > 0"
                class="player-amm"
              ></span>
              <span
                v-if="getPlayerHome(Object.keys(item)[0]).redCards > 0"
                class="player-esp"
              ></span>
              <span
                v-if="getPlayerHome(Object.keys(item)[0]).goals > 0"
                class="player-goal"
                ><img class="player-goal" :src="ball"
              /></span>
              <div class="player-tooltip">
                <div style="min-width: 150px">
                  <div class="player_thumb">
                    <img
                      width="30"
                      :src="getPlayerHome(Object.keys(item)[0]).imageDataURL"
                    />
                  </div>
                  <div class="player_info">
                    <h1>{{ getPlayerHome(Object.keys(item)[0]).shortName }}</h1>
                    <h2>
                      <span
                        v-if="getPlayerHome(Object.keys(item)[0]).playerOut > 0"
                        ><img :src="subOut" />{{
                          getPlayerHome(Object.keys(item)[0]).playerOut
                        }}'</span
                      >
                      <span
                        v-if="
                          getPlayerHome(Object.keys(item)[0]).yellowCards > 0
                        "
                        ><img :src="yellow" />{{
                          getPlayerHome(Object.keys(item)[0]).yellowCards
                        }}'</span
                      >
                      <span
                        v-if="getPlayerHome(Object.keys(item)[0]).redCards > 0"
                        ><img :src="red" />{{
                          getPlayerHome(Object.keys(item)[0]).redCards
                        }}'</span
                      >
                      <span v-if="getPlayerHome(Object.keys(item)[0]).goals > 0"
                        ><img
                          :src="ball"
                          v-for="n in parseInt(
                            getPlayerHome(Object.keys(item)[0]).goals
                          )"
                          :key="n"
                      /></span>
                    </h2>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul
            class="gears-abs gears-fit dotsTeam teamB"
            v-if="Object.keys(formation_away)[0]"
          >
            <li
              v-for="(item, id) in formation_away[
                Object.keys(formation_away)[0]
              ].players"
              :key="id"
              :class="
                'teamB_' +
                item[Object.keys(item)[0]]['position'] +
                '' +
                (getPlayerAway(Object.keys(item)[0]).playerOut > 0
                  ? ' substituted'
                  : '')
              "
            >
              {{ getPlayerAway(Object.keys(item)[0]).shirtNumber }}
              <span class="player-name">{{
                getPlayerAway(Object.keys(item)[0]).shortName
              }}</span>
              <span
                v-if="getPlayerAway(Object.keys(item)[0]).yellowCards > 0"
                class="player-amm"
              ></span>
              <span
                v-if="getPlayerAway(Object.keys(item)[0]).redCards > 0"
                class="player-esp"
              ></span>
              <span
                v-if="getPlayerAway(Object.keys(item)[0]).goals > 0"
                class="player-goal"
              >
                <img class="player-goal" :src="ball" />
              </span>
              <div class="player-tooltip">
                <div style="min-width: 150px">
                  <div class="player_thumb">
                    <img
                      width="30"
                      :src="getPlayerAway(Object.keys(item)[0]).imageDataURL"
                    />
                  </div>
                  <div class="player_info">
                    <h1>{{ getPlayerAway(Object.keys(item)[0]).shortName }}</h1>
                    <h2>
                      <span
                        v-if="getPlayerAway(Object.keys(item)[0]).playerOut > 0"
                        ><img :src="subOut" />{{
                          getPlayerAway(Object.keys(item)[0]).playerOut
                        }}'</span
                      >
                      <span
                        v-if="
                          getPlayerAway(Object.keys(item)[0]).yellowCards > 0
                        "
                        ><img :src="yellow" />{{
                          getPlayerAway(Object.keys(item)[0]).yellowCards
                        }}'</span
                      >
                      <span
                        v-if="getPlayerAway(Object.keys(item)[0]).redCards > 0"
                        ><img :src="red" />{{
                          getPlayerAway(Object.keys(item)[0]).redCards
                        }}'</span
                      >
                      <span v-if="getPlayerAway(Object.keys(item)[0]).goals > 0"
                        ><img
                          :src="ball"
                          v-for="n in parseInt(
                            getPlayerAway(Object.keys(item)[0]).goals
                          )"
                          :key="n"
                      /></span>
                    </h2>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="teamSubstitutions clearfix">
          <ul class="dotsTeam teamA">
            <li
              v-for="(player, index) in getSubstitutionsHome"
              :key="'subA_' + index"
            >
              {{ player.shirtNumber }}
              <span v-if="player.yellowCards > 0" class="player-amm"></span>
              <span v-if="player.redCards > 0" class="player-esp"></span>
              <span v-if="player.goals > 0" class="player-goal"
                ><img class="player-goal" :src="ball"
              /></span>
              <div class="player-tooltip">
                <div style="min-width: 150px">
                  <div class="player_thumb">
                    <img width="30" :src="player.imageDataURL" />
                  </div>
                  <div class="player_info">
                    <h1>{{ player.shortName }}</h1>
                    <h2>
                      <span v-if="player.playerIn > 0"
                        ><img :src="subIn" />{{ player.playerIn }}'</span
                      >
                      <span v-if="player.yellowCards > 0"
                        ><img :src="yellow" />{{ player.yellowCards }}'</span
                      >
                      <span v-if="player.redCards > 0"
                        ><img :src="red" />{{ player.redCards }}'</span
                      >
                      <span v-if="player.goals > 0"
                        ><img
                          :src="ball"
                          v-for="n in parseInt(player.goals)"
                          :key="n"
                      /></span>
                    </h2>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="dotsTeam teamB">
            <li
              v-for="(player, index) in getSubstitutionsAway"
              :key="'subB_' + index"
            >
              {{ player.shirtNumber }}
              <span v-if="player.yellowCards > 0" class="player-amm"></span>
              <span v-if="player.redCards > 0" class="player-esp"></span>
              <span v-if="player.goals > 0" class="player-goal"
                ><img class="player-goal" :src="ball"
              /></span>
              <div class="player-tooltip">
                <div style="min-width: 150px">
                  <div class="player_thumb">
                    <img width="30" :src="player.imageDataURL" />
                  </div>
                  <div class="player_info">
                    <h1>{{ player.shortName }}</h1>
                    <h2>
                      <span v-if="player.playerIn > 0"
                        ><img :src="subIn" />{{ player.playerIn }}'</span
                      >
                      <span v-if="player.yellowCards > 0"
                        ><img :src="yellow" />{{ player.yellowCards }}'</span
                      >
                      <span v-if="player.redCards > 0"
                        ><img :src="red" />{{ player.redCards }}'</span
                      >
                      <span v-if="player.goals > 0"
                        ><img
                          :src="ball"
                          v-for="n in parseInt(player.goals)"
                          :key="n"
                      /></span>
                    </h2>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="is_search" align="center" style="width: 100%">
        <p>Loading players formations...</p>
        <img :src="loadingImg" style="width: 150px" />
      </div>
      <b-button
        variant="primary"
        class="mt-3"
        @click="salvaValutazioni"
        :disabled="waiting"
        >Save</b-button
      >
    </b-modal>
  </b-container>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import MatchCard from "@/components/MatchCard.vue";
import Flag from "@/components/Flag.vue";
import loadingImg from "@/assets/images/loading.gif";
import ball from "@/assets/images/ball.png";
import subIn from "@/assets/images/in.png";
import subOut from "@/assets/images/out.png";
import yellow from "@/assets/images/yellow.png";
import red from "@/assets/images/red.png";
import grad1 from "@/assets/images/grad1.svg";
import grad2 from "@/assets/images/grad2.svg";
import grad3 from "@/assets/images/grad3.svg";
import grad4 from "@/assets/images/grad4.svg";
import grad5 from "@/assets/images/grad5.svg";
import InfiniteScroll from "v-infinite-scroll";
export default {
  components: {
    MatchCard,
    Flag,
    grad1,
    grad2,
    grad3,
    grad4,
    grad5,
    "v-infinite-scroll": InfiniteScroll,
  },

  data: function () {
    return {
      waiting: true,
      loading: false,
      osservatori: [],
      gradimenti: [],
      labelGrad: ["WIN", "QUALIFY", "GO", "STAY", "WIN"],
      siglaGrad: ["TOP", "CL", "EL", "A", "B"],
      partite: [],
      total: 0,
      page: 1,
      filters: {
        data: "",
        squadra: "",
        osservatore: 0,
      },
      form: {
        wyscout_id: null,
        partita: null,
        competizione: null,
        data_partita: null,
        ora_partita: null,
      },
      aree: [],
      search_area: "",
      competizioni: [],
      search_competizione: "",
      squadre: [],
      search_squadra: "",
      matches: [],
      show_aree: false,
      show_competizioni: false,
      show_squadre: false,
      show_matches: false,
      is_search: false,
      home: [],
      away: [],
      homeId: null,
      awayId: null,
      giocatori: {},
      formazioni: [],
      loadingImg,
      ball,
      subIn,
      subOut,
      yellow,
      red,
    };
  },

  created: function () {
    this.getPartite();
    this.getAree();
    this.getGradimenti();
    this.getOsservatori();
  },

  methods: {
    getAree() {
      this.$http.get("/ws/aree").then((response) => {
        this.aree = response.data;
      });
    },
    getGradimenti() {
      this.$http.get("/gradimenti").then((response) => {
        this.gradimenti = response.data;
      });
    },
    getPartite() {
      this.$http.get("/scouting/partite").then((response) => {
        this.partite = response.data.data;
        this.total = response.data.total;
        this.waiting = false;
      });
    },

    getOsservatori() {
      this.$http.get("/osservatori").then((response) => {
        this.osservatori = response.data;
      });
    },

    nextPage() {
      if (this.partite.length < this.total) {
        this.page++;
        this.loading = true;
        this.$http
          .get("/scouting/partite?page=" + this.page, {
            params: this.filters,
          })
          .then((response) => {
            this.partite = this.partite.concat(response.data.data);
            this.loading = false;
          });
      }
    },

    filtraPartite() {
      this.loading = true;
      this.partite = [];
      this.page = 1;
      this.$http
        .get("/scouting/partite", { params: this.filters })
        .then((response) => {
          this.partite = response.data.data;
          this.total = response.data.total;
          this.loading = false;
        });
    },

    openCercaPartita() {
      this.form.wyscout_id = null;
      this.form.partita = null;
      this.form.competizione = null;
      this.form.data_partita = null;
      this.form.ora_partita = null;
      this.competizioni = [];
      this.show_aree = false;
      this.show_competizioni = false;
      this.show_matches = false;
      this.$refs.matchModal.show();
    },
    cercaPartita() {
      this.show_aree = true;
      this.show_competizioni = false;
      this.show_matches = false;
      this.search_area = "";
      this.search_competizione = "";
    },
    selezionaArea: function (sigla) {
      this.show_aree = false;
      this.competizioni = [];
      this.is_search = true;
      this.show_competizioni = true;
      this.$http.get("/ws/get/competitions/" + sigla).then((response) => {
        this.competizioni = response.data;
        this.is_search = false;
      });
    },

    /*  selezionaCompetizione: function (competizione) {
      var data = 0;
      if (this.form.data_partita != null) {
        data = moment(this.form.data_partita + " 23:59:59") / 1000;
      }
      this.show_competizioni = false;
      this.matches = [];
      this.is_search = true;
      this.show_matches = true;
      this.form.competizione = competizione.name;
      this.$http
        .get(
          "/ws/get/matches/by/competition/and/date/" +
            competizione.wyId +
            "/" +
            data
        )
        .then((response) => {
          this.matches = response.data;
          this.is_search = false;
        });
    },  */

    selezionaCompetizione: function (competizione) {
      this.show_competizioni = false;
      this.squadre = [];
      this.is_search = true;
      this.show_squadre = true;
      this.form.competizione = competizione.name;
      this.$http
        .get("/ws/get/teams/by/competition/" + competizione.wyId)
        .then((response) => {
          this.squadre = response.data;
          this.is_search = false;
        });
    },

    selezionaSquadra: function (squadra) {
      this.show_squadre = false;
      this.matches = [];
      this.is_search = true;
      this.show_matches = true;
      this.$http
        .get("/ws/get/matches/by/team/" + squadra.wyId)
        .then((response) => {
          this.matches = response.data;
          this.is_search = false;
        });
    },

    selezionaPartita: function (partita) {
      this.show_matches = false;
      //  this.form.partita = partita.nome_partita;
      //  this.form.data_partita = partita.data_partita;
      //  this.form.ora_partita = partita.ora_partita;
      this.form.partita = partita.label;
      this.form.data_partita = partita.date.split(" ")[0];
      this.form.ora_partita = partita.date.split(" ")[1];
      this.form.wyscout_id = partita.matchId;
    },

    openValutaGiocatori() {
      this.$refs.matchModal.hide();
      this.home = [];
      this.away = [];
      this.giocatori = {};
      this.formazioni = [];
      this.is_search = true;
      this.$refs.playersModal.show();
      this.$http
        .get("/ws/get/match/with/players/" + this.form.wyscout_id)
        .then((response) => {
          this.home = response.data.home;
          this.away = response.data.away;
          this.homeId = response.data.homeId;
          this.awayId = response.data.awayId;
          for (var i in this.home) {
            var item = {
              id: null,
              gradimento: null,
              note: null,
              wyscout_team_id: this.homeId,
            };
            this.giocatori[this.home[i].wyId] = item;
          }
          for (var i in this.away) {
            var item = {
              id: null,
              gradimento: null,
              note: null,
              wyscout_team_id: this.awayId,
            };
            this.giocatori[this.away[i].wyId] = item;
          }

          var partita = null;
          for (var i in this.partite) {
            if (this.partite[i].wyscout_id == this.form.wyscout_id) {
              partita = this.partite[i];
              break;
            }
          }

          if (partita) {
            for (var i in partita.valutazioni) {
              var valutazione = partita.valutazioni[i];
              if (
                valutazione.utente_id == this.user.id &&
                this.giocatori[valutazione.osservato.giocatore.wyscout_id]
              ) {
                this.giocatori[valutazione.osservato.giocatore.wyscout_id].id =
                  valutazione.id;
                this.giocatori[
                  valutazione.osservato.giocatore.wyscout_id
                ].gradimento = valutazione.gradimento_id;
                this.giocatori[
                  valutazione.osservato.giocatore.wyscout_id
                ].note = valutazione.note;
              }
            }
          }

          this.is_search = false;
        });

      this.$http
        .get("/ws/get/match/formations/" + this.form.wyscout_id)
        .then((response) => {
          this.formazioni = response.data;
        });
    },

    openMatch(wyscout_id) {
      this.form.wyscout_id = wyscout_id;
      this.openValutaGiocatori();
    },

    resetGradimento(wyId) {
      this.giocatori[wyId].gradimento = null;
    },

    salvaValutazioni: function () {
      this.$refs.playersModal.hide();
      this.waiting = true;
      this.$http
        .post(
          "/scouting/salva/valutazioni/partita/" + this.form.wyscout_id,
          this.giocatori
        )
        .then((response) => {
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
          this.getPartite();
        });
    },

    getPlayerHome(wyId) {
      return this.home.find((player) => player.wyId == wyId);
    },
    getPlayerAway(wyId) {
      return this.away.find((player) => player.wyId == wyId);
    },
  },

  watch: {
    "filters.data": function () {
      this.filtraPartite();
    },
    "filters.osservatore": function () {
      this.filtraPartite();
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },
    user: function () {
      return Auth.user;
    },
    filtered_aree: function () {
      if (this.search_area != "") {
        return this.aree.filter((area) =>
          area.paese.toLowerCase().includes(this.search_area.toLowerCase())
        );
      }
      return this.aree;
    },
    filtered_competizioni: function () {
      if (this.search_competizione != "") {
        return this.competizioni.filter((competizione) =>
          competizione.name
            .toLowerCase()
            .includes(this.search_competizione.toLowerCase())
        );
      }
      return this.competizioni;
    },
    filtered_squadre: function () {
      if (this.search_squadra != "") {
        return this.squadre.filter((squadra) =>
          squadra.officialName
            .toLowerCase()
            .includes(this.search_squadra.toLowerCase())
        );
      }
      return this.squadre;
    },
    formation_home: function () {
      if (this.formazioni[this.homeId]) {
        return this.formazioni[this.homeId]["1H"][
          Object.keys(this.formazioni[this.homeId]["1H"])[0]
        ];
      }
      return [];
    },
    formation_away: function () {
      if (this.formazioni[this.awayId]) {
        return this.formazioni[this.awayId]["1H"][
          Object.keys(this.formazioni[this.awayId]["1H"])[0]
        ];
      }
      return [];
    },
    getSubstitutionsHome() {
      return this.home.filter((player) => player.playerIn > 0);
    },
    getSubstitutionsAway() {
      return this.away.filter((player) => player.playerIn > 0);
    },
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
};
</script>
